/* Estilos para el contenedor del chat */

html, body {
    height: 100%;
    margin: 0;
    padding: 0;
  }
  
  .chat-container {
    width: 100%;
    margin-top: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow-y: auto;
    max-height: 800px;
    padding: 10px;
    min-height: 500px;
  }
  
  /* Estilos para los mensajes */
  .message {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
  }
  
  /* Estilos para el remitente */
  .message-sender {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  /* Estilos para el texto del mensaje */
  .message-text {
    background-color: #f0f0f0;
    color: #333;
    border-radius: 5px;
    padding: 8px;
  }
  
  /* Estilos para el campo de entrada */
  .message-input {
    width: 100%;
    border: none;
    border-top: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    font-size: 16px;
    font-family: Arial, sans-serif;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
  }
  
  .message-input:focus {
    outline: none;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  }

  /* Estilos para los mensajes */
.message {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
  }
  
  /* Estilos para el remitente */
  .message-sender {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  /* Estilos para el texto del mensaje */
  .message-text {
    background-color: #f0f0f0;
    color: #333;
    border-radius: 5px;
    padding: 8px;
    animation: fadeIn 0.5s ease; /* Animación para hacer que cada letra se muestre gradualmente */
  }
  
  /* Animación para mostrar cada letra gradualmente */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  